.Polaris-Stack {
  margin-top: -1.6rem;
  margin-left: -1.6rem;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.Polaris-Stack--distributionEqualSpacing {
  justify-content: space-between;
}

.Polaris-Stack--alignmentCenter {
  align-items: center;
}

.Polaris-Stack__Item {
  flex: 0 0 auto;
  min-width: 0;
}

.Polaris-Stack__Item--fill {
  flex: 1 1 auto;
}

.Polaris-Stack > .Polaris-Stack__Item {
  margin-top: 1.6rem;
  margin-left: 1.6rem;
  max-width: 100%;
}

.Polaris-Icon {
  display: block;
  height: 2rem;
  width: 2rem;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
}

.Polaris-Icon__Svg, .Polaris-Icon__Img {
  position: relative;
  display: block;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

.Polaris-Collapsible {
  padding-top: 0;
  padding-bottom: 0;
  max-height: 0;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: max-height;
  transition-property: max-height;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0, 0, 0.42, 1);
}
